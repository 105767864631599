'use client';

import { useState } from 'react';

import { useServerInsertedHTML } from 'next/navigation';
import Script from 'next/script';

import { AssinyThemeProvider } from '@/providers/assiny-theme-provider';
import { AuthProvider } from '@/providers/auth-provider';
import ReactQueryContext from '@/providers/react-query';
import ToastContainerWrapper from '@/providers/toast-provider';
import * as S from '@/styles/globals';
import GlobalStyle from '@/utils/themes/global';
import { ServerStyleSheet, StyleSheetManager } from 'styled-components';

function StyledComponentsRegistry({ children }: { children: React.ReactNode }) {
  // Only create stylesheet once with lazy initial state
  // x-ref: https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
  const [styledComponentsStyleSheet] = useState(() => new ServerStyleSheet());

  useServerInsertedHTML(() => {
    const styles = styledComponentsStyleSheet.getStyleElement();
    styledComponentsStyleSheet.instance.clearTag();
    return styles;
  });

  if (typeof window !== 'undefined') return <>{children}</>;

  return (
    <StyleSheetManager sheet={styledComponentsStyleSheet.instance}>
      {children}
    </StyleSheetManager>
  );
}

export default function WrapperLayout({
  children,
  className,
}: {
  children: React.ReactNode;
  className: string;
}) {
  const environment = process.env.NODE_ENV;

  return (
    <StyledComponentsRegistry>
      <AssinyThemeProvider>
        <html className={className} lang="pt-br">
          <GlobalStyle />
          {environment === 'production' && (
            <Script id="clarity-mapper">
              {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "j5y32ry045")`}
            </Script>
          )}
          <S.Body>
            <ReactQueryContext>
              <AuthProvider>{children}</AuthProvider>
            </ReactQueryContext>
            <ToastContainerWrapper />
          </S.Body>
        </html>
      </AssinyThemeProvider>
    </StyledComponentsRegistry>
  );
}
