import styled, { css } from 'styled-components';

export const Body = styled.body`
  &.menuOpen {
    overflow: hidden;
  }
`;

export const Main = styled.main``;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

export const WrapperContent = styled.div<{ noLateralMenu?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: ${(props) => (props.noLateralMenu ? '0' : '260px')};
  justify-content: ${(props) => (props.noLateralMenu ? 'center' : '')};

  @media (max-width: 768px) {
    padding-left: 0;
  }
`;
export const Container = styled.main`
  ${({ theme }) => css`
    padding-bottom: ${theme.spacing['48']};
  `}
`;

export const HeaderContainer = styled.header`
  ${({ theme }) => css`
    background: ${theme.colors.headerGradient};
  `}
`;

export const HeaderItem = styled.section`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1024px;
    margin: auto;
    padding: ${theme.spacing['40']} ${theme.spacing['16']};

    &.with-button {
      flex-direction: column;
      align-items: flex-start;
      gap: ${theme.spacing['24']};

      ${theme.breakpoints.tablet720} {
        flex-direction: row;
      }
    }

    &.with-card {
      padding: ${theme.spacing['40']} ${theme.spacing['16']};
      ${theme.breakpoints.tablet720} {
        padding: ${theme.spacing['40']} ${theme.spacing['16']}
          ${theme.spacing['80']};
      }
    }
  `}
`;

export const HeaderCard = styled.section`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing['24']} ${theme.spacing['16']};
    position: relative;
    max-width: 1024px;
    margin: auto auto -10%;
    ${theme.breakpoints.tablet720} {
      margin-bottom: -15%;
    }
    ${theme.breakpoints.tablet960} {
      margin-bottom: ${theme.spacing['40']};
    }
  `}
`;

export const HeaderCardContainer = styled.div`
  ${({ theme }) => css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: ${theme.spacing['08']};
      flex: 1;
      width: 100%;
      align-items: center;
      transform: translateY(-10%);
      top: 50%;
      position: relative;
      ${theme.breakpoints.tablet720} {
        transform: translateY(-20%);
      }
      ${theme.breakpoints.tablet960} {
        position: absolute;
        transform: translateY(-50%);
        top: 0;
        flex-direction: row;
        padding-top: ${theme.spacing['40']};
        align-items: initial;
      }

      &.full > * {
        ${theme.breakpoints.tablet720} {
          max-width: none;
        }
      }

      & > * {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: ${theme.spacing['32']} ${theme.spacing['16']};
        flex: 1;
        background: ${theme.colors.white};
        border-radius: ${theme.radius['sm']};
        border: 1px solid ${theme.colors.gray['200']};
        box-shadow: ${theme.shadows['xl']};
        max-width: none;
        width: 100%;
        ${theme.breakpoints.tablet960} {
          max-width: 610px;
        }
      }
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.dark.light};
    font-size: ${theme.typography.fontSize['24'].size};
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: ${theme.typography.fontSize['24'].lineHeight};
    margin-bottom: ${theme.spacing['04']};
  `}
`;

export const Description = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.gray['300']};
    font-size: ${theme.typography.fontSize['14'].size};
    font-weight: ${theme.typography.fontWeight.regular};
    line-height: ${theme.typography.fontSize['14'].lineHeight};
  `}
`;
